import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Assembly, testing, and packaging (ATP)`}</h4>
    <p>{`At the end of the fabrication process, the finished wafer contains dozens of chips in a grid pattern. During assembly, testing, and packaging, the wafer is separated into individual chips, or "dies." Each chip is mounted on a frame with wires that connect the chip to external devices, and enclosed in a protective casing. This produces the familiar look of a dark gray rectangle with metal pins at the edges. The chip is also tested to ensure it operates as intended.`}</p>
    <p>{`Although ATP was historically low value, as transistor densities in logic chips have increased exponentially, packaging has increasingly become a bottleneck on chip performance.`}</p>
    <p>{`ATP occurs under two business models: (1) as in-house ATP services performed by integrated device manufacturers (IDMs) and foundries after fabrication; and (2) by outsourced semiconductor assembly and test (OSAT) firms, which perform ATP for third-party customers. Firms headquartered in Taiwan, the United States, China, and South Korea are the main providers of ATP services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      